﻿(function (w, d, $) {
  w.initNavbarJS = function initNavbarJS() {
    function utilityNavPosition() {
      let $utilityNav = $(
          ".navbar-subsite #navbar-hamburger-menu .dropdown-menu"
        ),
        offsetMargin = 15,
        minDesktopWidth = 768;
      //Handles Section Ender height.
      if (window.innerWidth >= minDesktopWidth) {
        $utilityNav.css(
          "left",
          -($utilityNav.closest(".navbar-subsite").width() - offsetMargin) +
            "px"
        );
      } else {
        $utilityNav.css("left", "0");
      }
    }
    utilityNavPosition();
    $(w).resize(utilityNavPosition);
  };
})(window, document, jQuery);

//Handle keyboard, touch thru subsite nav
$(
  ".navbar-subsite .dropdown.level-1, .navbar-subsite .dropdown.level-1 a.dropdown-toggle"
).on("mouseenter", function (e) {
  if (window.innerWidth >= 768) {
    $(".navbar-subsite .primary .dropdown.open").each(function () {
      $(this).removeClass("open");
    });
  }
});
//Handle keyboard, touch thru subsite nav
$(
  ".navbar-subsite .dropdown.level-1, .navbar-subsite .dropdown.level-1 a.dropdown-toggle"
).on("keydown", function (e) {
  let keyCode = e.keyCode || e.which,
    $target = $(e.target);
  if (window.innerWidth >= 768) {
    function close($elem) {
      if ($elem?.hasClass("dropdown")) {
        $elem.removeClass("open");
      } else {
        $elem.closest(".dropdown").removeClass("open");
      }
    }
    if (
      keyCode === 27 ||
      (e.shiftKey && keyCode === 9 && $target.hasClass("dropdown-toggle"))
    ) {
      close($target);
    }
  }
});
function open($elem) {
  try {
    if ($elem && elem.hasClass(".dropdown")) {
      $elem.addClass("open");
    } else {
      $elem.closest(".dropdown").addClass("open");
    }
  } catch {}
}
//Handle keyboard, touch thru subsite nav
$(
  ".navbar-subsite .dropdown.level-1, .navbar-subsite .dropdown.level-1 a.dropdown-toggle"
).on("focus", function (e) {
  let $target = $(e.target);
  if (window.innerWidth >= 768) {
    open($target);
  }
});
//Close all open subsite menus when touching off the menu
$(document.body).on("touchstart", function (e) {
  if (window.innerWidth >= 768) {
    $(".navbar-subsite .primary .dropdown.open").each(function () {
      $(this).removeClass("open");
    });
  }
});

$(".navbar-subsite .dropdown.level-1 .level-2 a").on("keydown", function (e) {
  let keyCode = e.keyCode || e.which,
    $target = $(e.target);
  if (
    !e.shiftKey &&
    keyCode === 9 &&
    $target.closest(".level-2").is(":last-child")
  ) {
    $target.closest(".dropdown").removeClass("open");
  }
});

//###### Begin Mobile Behaviors ######
// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
$(".navbar-subsite .dropdown-menu.navbar-mobile").on(
  "show.bs.dropdown",
  function (e) {
    $(".hamburger-mobile").addClass("open");
    $(this)
      .first()
      .stop(true, true)
      .slideDown(200, function () {
        $(this)
          .find(".mobile-nav-backdrop")
          .height(
            $("body").innerHeight() -
              $(this).find(".mobile-nav-container").innerHeight()
          );
      });
  }
);

$(".navbar-subsite .navbar-collapse.navbar-mobile").on(
  "show.bs.collapse",
  function (e) {
    $(".hamburger-mobile").addClass("open");
    $(this)
      .stop(true, true)
      .slideDown(600, function () {
        $(this)
          .find(".mobile-nav-backdrop")
          .height(
            $("body").innerHeight() -
              $(this).find(".mobile-nav-container").innerHeight()
          );
      });
  }
);
// ADD SLIDEUP ANIMATION TO DROPDOWN //
$(".navbar-subsite .dropdown-menu.navbar-mobile").on(
  "hide.bs.dropdown",
  function (e) {
    $(".hamburger-mobile").removeClass("open");
    $(this).first().stop(true, true).slideUp(200);
  }
);
$(".navbar-subsite .navbar-collapse.navbar-mobile").on(
  "hide.bs.collapse",
  function (e) {
    $(".hamburger-mobile").removeClass("open");
    $(this).stop(true, true).slideUp(400);
  }
);

//Mobile slide-in offcanvas toggle
$("[data-toggle=offcanvas], [data-toggle=offcanvas-subsite]").on(
  "click",
  function (e) {
    if (window.innerWidth < 768) {
      if (
        $(e.target).hasClass("offcanvas") ||
        $(e.target).hasClass("offcanvas-link") ||
        $(e.target).parent().parent().hasClass("offcanvas") ||
        $(e.target).parent().hasClass("offcanvas-link")
      ) {
        e.stopPropagation();
        e.preventDefault();
        //set height of secondary nav to be the same as primary
        $(this)
          .parent()
          .find(".sidebar-offcanvas .sub-menu")
          .css("min-height", $(".mobile-nav-container").height() + "px");
        //slide secondary into place
        $(this)
          .parent()
          .find(".row-offcanvas")
          .first()
          .toggleClass("active")
          .animate({ right: "100%" }, 100);
      }
    }
  }
);
//###### End Mobile Behaviors ######s
